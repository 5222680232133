<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          <p>{{ $t("editbranch") }}</p>
        </h3>
      </div>
      <!-- form -->
      <div class="card-body row d-flex justify-content-center">
        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >เลือกบริษัท</label
            >
            <select
              v-model="form.companyId"
              class="form-select fadeIn fifth"
              aria-label="Default select example"
              disabled
            >
              <option v-for="item in companyItems" :key="item" :value="item.id">
                <!-- {{ item.company_code }} - {{ item.company_name }} -->
                {{ item.company_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="mainWarehouseCode"
            class="required form-label col-sm-10"
            >{{ $t("branchcode") }}</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.code"
              type="text"
              class="form-control"
              id="mainWarehouseCode"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอกข้อมูล</div>
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <label
            for="mainWarehouseName"
            class="required form-label col-sm-10"
            >{{ $t("initials") }}</label
          >
          <div class="col-sm-12">
            <input
              v-model="form.short_name"
              type="text"
              class="form-control"
              id="mainWarehouseName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอกข้อมุล</div>
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <label
            for="mainWarehouseName"
            class="required form-label col-sm-10"
            >{{ $t("branchname") }}</label
          >
          <div class="col-sm-12">
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              id="mainWarehouseName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอกข้อมูล</div>
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <label
            for="secondaryProductCode"
            class="required form-label col-sm-10"
            >{{ $t("full_name") }}</label
          >
          <div class="col-sm-12">
            <input
              v-model="form.full_name"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <label
            for="secondaryProductCode"
            class="required form-label col-sm-10"
            >{{ $t("address") }}</label
          >
          <div class="col-sm-12">
            <input
              v-model="form.address"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <label
            for="secondaryProductCode"
            class="required form-label col-sm-10"
            >{{ $t("moo") }}</label
          >
          <div class="col-sm-12">
            <input
              v-model="form.moo"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <label
            for="secondaryProductCode"
            class="required form-label col-sm-10"
            >{{ $t("subdistrict") }}</label
          >
          <div class="col-sm-12">
            <input
              v-model="form.sub_district"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <label
            for="secondaryProductCode"
            class="required form-label col-sm-10"
            >{{ $t("district") }}</label
          >
          <div class="col-sm-12">
            <input
              v-model="form.district"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <label
            for="secondaryProductCode"
            class="required form-label col-sm-10"
            >{{ $t("province") }}</label
          >
          <div class="col-sm-12">
            <input
              v-model="form.province"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <label
            for="secondaryProductCode"
            class="required form-label col-sm-10"
            >{{ $t("postcode") }}</label
          >
          <div class="col-sm-12">
            <input
              v-model="form.postcode"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
        </div>
        <div class="col-sm-5 mb-4">
          <label
            for="secondaryProductCode"
            class="required form-label col-sm-10"
            >{{ $t("tax_id") }}</label
          >
          <div class="col-sm-12">
            <input
              v-model="form.tax_id"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="button"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loadingUpdate"
            >
              <div
                v-if="loadingUpdate"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              <th>{{ $t("save") }}</th>
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              <th>{{ $t("cancel") }}</th>
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loader from "../../components/loaderUpdate.vue";
import whApi from "@/api/warehouse/";
import userApi from "@/api/user/";

export default {
  setup() {
    document.title = "J&N | แก้ไขกลุ่มสินค้าหลัก";
  },
  components: {
    Loader,
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    isSubmit: false,

    form: {
      name: "",
      code: "",
      short_name: "",
      companyId: "",
    },
    companyItems: [],
  }),
  created() {
    this.getAllCompany();
    this.getOne();
  },
  methods: {
    async getAllCompany() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await userApi.company.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.companyItems = responseData.data;
        // this.form.companyId = localStorage.getItem("companyId");
      }
      this.loading = false;
    },
    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.branch.getOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.form = {
          ...getOneResponse.data,
        };
        this.loading = false;
      }
    },
    async submit() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let updateResponse = [];

          await this.validateForm();
          await this.checkFormIsEmpty();
          this.isSubmit = true;

          this.form = {
            ...this.form,
            // status: this.form.status === "2" ? "0" : this.form.status,
            companyId: parseInt(localStorage.getItem("companyId")),
          };
          if (this.isValid) {
            this.loadingUpdate = true;
            updateResponse = await whApi.branch.update(
              this.$route.query.id,
              this.form
            );
            if (updateResponse.response_status === "SUCCESS") {
              Swal.fire({
                icon: "success",
                title: `แก้ไขสำเร็จ`,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push({
                  path: "/warehouse/BRANCH",
                  query: {},
                });
              });
              this.loadingUpdate = false;
            } else {
              Swal.fire({
                icon: "error",
                title: `${updateResponse.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.loadingUpdate = false;
            }
          }
        }
      });
    },
    checkFormIsEmpty() {
      !this.form.name
        ? (this.isValid = false)
        : !this.form.short_name
        ? (this.isValid = false)
        : !this.form.code
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/warehouse/BRANCH",
        query: {},
      });
    },
  },
};
</script>
